import { TranslateLoader } from '@ngx-translate/core';
import { forkJoin, from, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import merge from 'lodash/merge';

export class WebpackTranslateLoader implements TranslateLoader {
    public getTranslation(lang: string): Observable<any> {
        const appTranslations = from(import(`../../assets/i18n/${lang}.json`));

        const dependencyTranslations = from(
            import(
                `../../../node_modules/@deutsche-dienstrad/dd-benefit-mobility-hub/src/assets/i18n/mobility-hub.${lang}.json`
            ),
        );

        return forkJoin([appTranslations, dependencyTranslations]).pipe(
            map(([dependency, app]) => merge({}, dependency, app)), // Deep merge translations. Prioritise main app translations
        );
    }
}
