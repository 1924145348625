<ul class="list-group">
    @for (file of files(); track file; let idx = $index) {
        <li class="list-group-item d-flex align-items-center gap-2">
            <span class="flex-grow-1 text-break">{{ file.name }}</span>
            <button
                type="button"
                class="btn btn-light"
                (click)="deleteFileAtIdx.emit(idx)"
                [attr.aria-label]="'GENERIC.ACTIONS.DELETE_FILE' | translate"
            >
                <i
                    aria-hidden="true"
                    class="fal fa-trash-alt pointer"
                ></i>
            </button>
        </li>
    }
</ul>
