import { Component } from '@angular/core';

@Component({
    selector: 'dd-public-layout',
    templateUrl: './public-layout.component.html',
    styleUrls: ['./public-layout.scss'],
})
export class PublicLayoutComponent {
    constructor() {}

    public isWeihnachten(): boolean {
        const today = new Date();
        return today.getMonth() + 1 === 12;
    }
}
