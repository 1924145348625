<button
    type="button"
    *ngIf="
        titleResolver.currentRouteData?.title ||
        titleResolver.currentRouteData?.back
    "
    class="btn btn-light header-title__button h-100"
    (click)="navigateBack(titleResolver.currentRouteData?.back)"
    [disabled]="!titleResolver.currentRouteData?.back"
    [attr.aria-label]="'GENERIC.LABELS.BACK_TO_PREVIOUS_PAGE' | translate"
>
    <i
        aria-hidden="true"
        *ngIf="titleResolver.currentRouteData?.back"
        class="fal fa-chevron-left"
    ></i>
    <span>{{ titleResolver.currentRouteData?.title | translate }}</span>
</button>
