import { Component, computed, input } from '@angular/core';
import { AbstractControl, Validators } from '@angular/forms';

type FieldContentType = 'default' | 'checkbox';

@Component({
    selector: 'mrc-form-item-field',
    templateUrl: './form-item-field.component.html',
    styleUrls: ['./form-item-field.component.scss'],
})
export class FormItemFieldComponent {
    public control = input<AbstractControl>();
    public label = input<string>();
    public id = input<string>();
    public contentType = input<FieldContentType>('default');
    public layout = input<string>();
    public reversed = input<false>();
    public tooltipText = input<string>();
    public isOutCard = input<boolean>();
    public showErrorIcon = input<boolean>(false);

    public layoutClasses = computed(
        () => this.layout()?.replace('inline', 'form-group-inline') ?? '',
    );

    public contentBasedID = computed(() =>
        this.contentType() === 'checkbox' ? `${this.id()}-input` : this.id(),
    );

    public get isRequired(): boolean {
        return (
            this.control()?.enabled &&
            (this.control()?.hasValidator(Validators.required) ||
                this.control()?.hasValidator(Validators.requiredTrue))
        );
    }
}
