<div class="card">
    <div class="card-body">
        <div class="logo">
            <img
                alt=""
                src="./assets/images/logos/dd-logo.png"
            />
        </div>
        <h1
            class="title"
            translate
        >
            LOGIN.LOGIN_TITLE
        </h1>
        <div class="row justify-content-center">
            <p
                class="cdk-visually-hidden"
                translate
            >
                DYNAMIC_FORM.MANDATORY_FIELD
            </p>
            <form
                class="col-md-8"
                [formGroup]="loginForm"
                (ngSubmit)="login()"
                ddFocusInvalidInput
            >
                <div class="mb-4">
                    <mrc-form-item-field
                        [label]="'USER.EMAIL' | translate"
                        [control]="loginForm.controls.email"
                        [id]="'email'"
                    >
                        <input
                            id="email"
                            type="email"
                            name="email"
                            class="form-control"
                            formControlName="email"
                            [placeholder]="'USER.EMAIL' | translate"
                            data-testid="email"
                        />
                    </mrc-form-item-field>
                </div>
                <div class="mb-4">
                    <mrc-form-item-field
                        [label]="'LOGIN.PASSWORD' | translate"
                        [control]="loginForm.controls.password"
                        [id]="'password'"
                    >
                        <input
                            id="password"
                            type="password"
                            name="password"
                            class="form-control"
                            formControlName="password"
                            [placeholder]="'LOGIN.PASSWORD' | translate"
                            data-testid="password"
                        />
                    </mrc-form-item-field>
                </div>
                <div>
                    <button
                        type="submit"
                        class="btn btn-primary w-100"
                        data-testid="login"
                    >
                        <span translate>LOGIN.LOGIN</span>
                    </button>
                </div>
                <div class="mt-3">
                    <a
                        routerLink="recovery-password"
                        class="float-end link-inherit"
                        translate
                        >LOGIN.FORGOT_PASSWORD</a
                    >
                </div>
            </form>
            <p
                class="mb-0 mt-2"
                aria-hidden="true"
                translate
            >
                DYNAMIC_FORM.MANDATORY_FIELD
            </p>
        </div>
    </div>
</div>

<div class="card">
    <div class="card-body">
        <div class="row justify-content-center">
            <div class="col-md-8 form-group">
                <span translate> LOGIN.NEW_REGISTRATION_TEXT </span>
                <!-- Checking Hosting-Provider-Mechanism -->
                <!-- It should stay here -->
                <span
                    *ngIf="up"
                    class="health-text"
                    aria-hidden="true"
                >
                    Up
                </span>
                <div>
                    <a
                        href="https://www.deutsche-dienstrad.de/kontakt/"
                        target="_blank"
                        class="btn btn-outline-dark w-100"
                        translate
                        >LOGIN.NEW_REGISTRATION_BUTTON</a
                    >
                </div>
            </div>
        </div>
    </div>
</div>
