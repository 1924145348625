<div
    [class]="'form-group ' + layoutClasses()"
    [ngClass]="{
        'd-flex': contentType() === 'checkbox',
        'justify-content-end flex-row-reverse':
            contentType() === 'checkbox' && reversed(),
    }"
>
    <div class="d-flex justify-content-between align-items-center">
        @if (label()) {
            <label
                [ngClass]="{
                    'out-card': isOutCard(),
                    pointer: control()?.enabled,
                }"
                [for]="contentBasedID()"
            >
                <span [innerHtml]="label()"></span>
                @if (isRequired) {
                    <span
                        class="field-star"
                        aria-hidden="true"
                    >
                        *
                    </span>
                }
            </label>
        }
        @if (tooltipText()) {
            <dd-info-tooltip [text]="tooltipText()" />
        }
    </div>

    <div class="form-control-holder">
        <ng-content></ng-content>
    </div>
</div>
<div
    [ngClass]="{
        'indention-left': contentType() === 'checkbox' && reversed(),
    }"
>
    <dd-validation-errors
        [dirty]="control()?.dirty"
        [invalid]="control()?.invalid"
        [errors]="control()?.errors"
        [showErrorIcon]="showErrorIcon()"
    ></dd-validation-errors>
</div>
