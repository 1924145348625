import { Component, input, output } from '@angular/core';

@Component({
    selector: 'dd-file-list',
    templateUrl: './file-list.component.html',
})
export class FileListComponent {
    public files = input.required<File[]>();
    public readonly deleteFileAtIdx = output<number>();
}
