import { HttpClient } from '@angular/common/http';
import { Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { LogoService } from '@core/services/logo/logo.service';
import { TypedFormGroup } from '@core/types/form.type';
import { CustomEmailValidator } from '@core/validators/custom-email.validator';
import { TranslateService } from '@ngx-translate/core';
import { ForgotPassword } from '@public/interfaces/forgot-password.interface';
import { ToastrService } from 'ngx-toastr';
import { catchError, throwError } from 'rxjs';

@Component({
    selector: 'dd-forgot-password',
    templateUrl: './forgot-password.component.html',
    styleUrls: ['./forgot-password.component.scss'],
})
export class ForgotPasswordComponent {
    public forgotPasswordForm = new FormGroup<TypedFormGroup<ForgotPassword>>({
        email: new FormControl(null, [
            Validators.required,
            CustomEmailValidator,
        ]),
    });
    public submitted = false;

    constructor(
        private toastrService: ToastrService,
        private httpClient: HttpClient,
        public logoService: LogoService,
        private translate: TranslateService,
    ) {}

    public forgotPassword(): void {
        if (this.forgotPasswordForm.disabled) return;

        this.forgotPasswordForm.updateValueAndValidity();

        if (this.forgotPasswordForm.invalid) return;

        this.forgotPasswordForm.disable();
        const data = this.forgotPasswordForm.getRawValue();

        this.httpClient
            .post('password/email', data)
            .pipe(
                catchError((error) => {
                    this.forgotPasswordForm.enable();
                    return throwError(() => error);
                }),
            )
            .subscribe(() => {
                this.submitted = true;
                this.toastrService.success(
                    this.translate.instant(
                        'PASSWORD.FORGOT_PASSWORD_EMAIL_SENT',
                    ),
                );
            });
    }
}
