import {
    IServerSideGetRowsRequest,
    LoadSuccessParams,
} from '@ag-grid-community/core';
import {
    HttpClient,
    HttpErrorResponse,
    HttpHeaders,
    HttpParams,
} from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { ServerError } from '@core/exceptions/error';
import { ResponseGeneric } from '@core/interfaces/response-generic.interface';
import { LoginMode } from '@core/services/auth/auth-settings.service';
import { SkipModuleUrl } from '@core/services/http/api.interceptor';
import { ProfileService } from '@core/services/profile/profile.service';
import { RedirectService } from '@core/services/redirect/redirect.service';
import { MODULE } from '@core/tokens/module.token';
import { Factory } from '@shared/factory';
import { ChartData } from '@shared/models/chart-data';
import { Page } from '@shared/models/page';
import { Profile } from '@shared/models/profile';
import { User } from '@shared/models/user';
import { Observable, catchError, map, throwError } from 'rxjs';
import { UserInfo } from './models/user-info';

export interface UserListRequest {
    activeUsers: boolean;
    inactiveUsers: boolean;
    pendingUsers: boolean;
    policyRejectedUsers: boolean;
    pageSize: number;
    page: number;
    search?: string;
}

export interface LoginAsResponse {
    portalDomain: string;
    token: { access_token; expires_in };
    expiryTimestamp: number;
    user: User;
}

@Injectable({
    providedIn: 'root',
})
export class UsersService {
    public profile: Profile;
    constructor(
        @Inject(MODULE) private module: LoginMode,
        private httpClient: HttpClient,
        private profileService: ProfileService,
        private redirectService: RedirectService,
    ) {
        this.profileService.getProfile().subscribe((profile) => {
            this.profile = profile;
        });
    }

    private static handleError(error: unknown): ServerError | unknown {
        if (error instanceof HttpErrorResponse) {
            return new ServerError();
        }
        return error;
    }

    public getPaginatedUsers(request: UserListRequest): Observable<Page<User>> {
        const params = new HttpParams({
            fromObject: this.transformRequestToQuery(request),
        });
        const headers = new HttpHeaders().set(SkipModuleUrl, '');
        let url = 'users';
        if (this.module === 'system') {
            url = `system/${url}`;
        }
        return this.httpClient.get<any>(url, { params, headers }).pipe(
            catchError((error) => {
                const handledError = UsersService.handleError(error);
                return throwError(() => handledError);
            }),
            map((response) => {
                const page = new Factory<Page<User>>(Page).fromObject(response);
                page.data = new Factory(User).fromArray(page.data);
                return page;
            }),
        );
    }

    public getAgGridUsers(
        request: IServerSideGetRowsRequest,
    ): Observable<LoadSuccessParams> {
        const headers = new HttpHeaders().set(SkipModuleUrl, '');
        const params = new HttpParams({
            fromObject: {
                globalView: String(this.profileService.global_view),
            },
        });
        let url = 'users/aggrid';
        if (this.module === 'system') {
            url = `system/${url}`;
        }
        return this.httpClient
            .post<LoadSuccessParams>(url, request, { params, headers })
            .pipe(
                catchError((error) => {
                    const handledError = UsersService.handleError(error);
                    return throwError(() => handledError);
                }),
            );
    }

    public all(portalId: number): Observable<User[]> {
        const headers = new HttpHeaders().set(SkipModuleUrl, '');
        let url = `users/all`;
        if (portalId) {
            url += `?portal_id=${portalId}`;
        }
        if (this.module === 'system') {
            url = `system/${url}`;
        }
        return this.httpClient
            .get<any>(url, { headers })
            .pipe(map((users) => new Factory(User).fromArray(users)));
    }

    public get(id: number | string, isSystemAdmin = false): Observable<User> {
        const headers = new HttpHeaders().set(SkipModuleUrl, '');
        let url = `users/${id}`;
        if (this.module === 'system') {
            url = `system/${url}`;
            if (isSystemAdmin) {
                url += '?isSystemAdmin=1';
            }
        }
        return this.httpClient
            .get<any>(url, { headers })
            .pipe(map(({ payload }) => new Factory(User).fromObject(payload)));
    }

    public getSystem(id: number | string): Observable<User> {
        const headers = new HttpHeaders().set(SkipModuleUrl, '');
        let url = `users/system/${id}`;
        if (this.module === 'system') {
            url = `system/${url}`;
        }
        return this.httpClient
            .get<any>(url, { headers })
            .pipe(map(({ payload }) => new Factory(User).fromObject(payload)));
    }

    public getUserCompany(
        id: number | string,
        isSystemAdmin = false,
    ): Observable<User> {
        const headers = new HttpHeaders().set(SkipModuleUrl, '');
        let url = `users/company/${id}`;
        if (this.module === 'system') {
            url = `system/${url}`;
            if (isSystemAdmin) {
                url += '?isSystemAdmin=1';
            }
        }
        return this.httpClient
            .get<any>(url, { headers })
            .pipe(map(({ payload }) => new Factory(User).fromObject(payload)));
    }

    public create(user: UserInfo): Observable<User> {
        const headers = new HttpHeaders().set(SkipModuleUrl, '');
        let url = 'users';
        if (this.module === 'system') {
            url = `system/${url}`;
        }
        return this.httpClient
            .post<{ payload: User }>(url, user, { headers })
            .pipe(map((result) => result.payload));
    }

    public findByEmail(email: string): Observable<User> {
        return this.httpClient.get<User>(`users/search`, {
            params: { email: email },
        });
    }

    public loginAs(
        redirectTo: string,
        tokenKey: string,
        userId: number,
        sameWindow = false,
        global_view = false,
        roleable_to_login = null,
    ): void {
        this.httpClient
            .get<LoginAsResponse>(`portal-users/${userId}/login-as`)
            .subscribe((response) => {
                response.user.active_role.roleable_id = roleable_to_login;
                if (sameWindow) {
                    location.href = this.redirectService.buildRedirectUrl(
                        redirectTo,
                        tokenKey,
                        response,
                        global_view,
                    );
                } else {
                    window.open(
                        this.redirectService.buildRedirectUrl(
                            redirectTo,
                            tokenKey,
                            response,
                            global_view,
                        ),
                        '_blank',
                    );
                }
            });
    }

    public update(
        id: number | string,
        user: UserInfo,
        isSystemAdmin = false,
        url_prefix = '',
    ): Observable<User> {
        const headers = new HttpHeaders().set(SkipModuleUrl, '');
        let url = url_prefix + `users/${id}`;
        if (this.module === 'system') {
            url = `system/${url}`;
            if (isSystemAdmin) {
                url += '?isSystemAdmin=1';
            }
        }
        return this.httpClient
            .put<{ payload: User }>(url, user, { headers })
            .pipe(map((result) => result.payload));
    }

    public updateUserLanguage(languageCode: string): Observable<User> {
        const headers = new HttpHeaders().set(SkipModuleUrl, '');
        const url = `users/changeLanguage`;
        return this.httpClient
            .post<{
                payload: User;
            }>(url, { languageCode: languageCode }, { headers })
            .pipe(map((result) => result.payload));
    }

    public updateUserCompany(
        id: number | string,
        user: User,
        isSystemAdmin = false,
    ): Observable<User> {
        const headers = new HttpHeaders().set(SkipModuleUrl, '');
        let url = `users/updateUserCompany/${id}`;
        if (this.module === 'system') {
            url = `system/${url}`;
            if (isSystemAdmin) {
                url += '?isSystemAdmin=1';
            }
        }
        return this.httpClient
            .put<{ payload: User }>(url, user, { headers })
            .pipe(map((result) => result.payload));
    }

    private transformRequestToQuery(request: UserListRequest): {
        [key: string]: string;
    } {
        const transformedObject: { [key: string]: string } = {};
        Object.keys(request).forEach((key) => {
            if (typeof request[key] === 'boolean') {
                transformedObject[key] = request[key] ? '1' : '0';
            } else {
                transformedObject[key] = request[key].toString();
            }
        });
        transformedObject['global_view'] = String(
            this.profileService.global_view,
        );
        return transformedObject;
    }

    public getCo2Savings(): Observable<ChartData> {
        let url = '';
        if (this.profile.isCompanyAdmin()) {
            url = `company-api/v1/co2-savings/` + this.profile.company.id;
        } else if (this.profile.isSupplier()) {
            url = `supplier-api/v1/co2-savings/` + this.profile.supplier.id;
        } else {
            return null;
        }

        const headers = new HttpHeaders().set(SkipModuleUrl, '');

        const paginatedParams = <UserListRequest>{
            activeUsers: false,
            inactiveUsers: false,
            pendingUsers: false,
            pageSize: 0,
            page: 0,
        };
        const params = this.transformRequestToQuery(paginatedParams);

        return this.httpClient.get<ChartData>(url, { params, headers });
    }

    public getOfferChart(): Observable<ChartData> {
        let url = '';
        if (this.profile.isCompanyAdmin()) {
            url = `company-api/v1/offer-chart/` + this.profile.company.id;
        } else if (this.profile.isSupplier()) {
            url = `supplier-api/v1/offer-chart/` + this.profile.supplier.id;
        } else {
            return null;
        }

        const headers = new HttpHeaders().set(SkipModuleUrl, '');
        const paginatedParams = <UserListRequest>{
            activeUsers: false,
            inactiveUsers: false,
            pendingUsers: false,
            pageSize: 0,
            page: 0,
        };
        const params = this.transformRequestToQuery(paginatedParams);

        return this.httpClient.get<ChartData>(url, { params, headers });
    }

    public getOrderChart(): Observable<ChartData> {
        let url = '';
        if (this.profile.isCompanyAdmin()) {
            url = `company-api/v1/order-chart/` + this.profile.company.id;
        } else if (this.profile.isSupplier()) {
            url = `supplier-api/v1/order-chart/` + this.profile.supplier.id;
        } else {
            return null;
        }

        const headers = new HttpHeaders().set(SkipModuleUrl, '');

        return this.httpClient.get<ChartData>(url, { headers });
    }

    public approveUser(
        id: number,
        data: { userActionWithText: boolean; userActionText: string },
    ): Observable<ResponseGeneric<User>> {
        return this.httpClient.post<ResponseGeneric<User>>(
            `users/${id}/approve`,
            data,
        );
    }

    public rejectUser(
        id: number,
        data: { userActionWithText: boolean; userActionText: string },
    ): Observable<ResponseGeneric<User>> {
        return this.httpClient.post<ResponseGeneric<User>>(
            `users/${id}/reject`,
            data,
        );
    }

    public deleteUser(
        id: number,
        data: { transferReason: string },
    ): Observable<ResponseGeneric<User>> {
        return this.httpClient.post<ResponseGeneric<User>>(
            `users/${id}/fulldelete`,
            data,
        );
    }

    public encryptUser(id: number): Observable<User> {
        const headers = new HttpHeaders().set(SkipModuleUrl, '');
        let url = `users/${id}/encrypt`;
        if (this.module === 'system') {
            url = `system/${url}`;
        }
        return this.httpClient
            .post<ResponseGeneric<User>>(url, {}, { headers })
            .pipe(map((user) => new Factory(User).fromObject(user)));
    }
}
