import { Component } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { TitleResolver } from '@core/services/title/title-resolver.service';

@Component({
    selector: 'dd-header-title',
    templateUrl: './header-title.component.html',
    styleUrls: ['./header-title.component.scss'],
})
export class HeaderTitleComponent {
    constructor(
        private activatedRoute: ActivatedRoute,
        private router: Router,
        public titleResolver: TitleResolver,
    ) {}

    public navigateBack(back: { url: string; queryParams?: Params }): void {
        this.router.navigate([back.url], {
            relativeTo: this.activatedRoute.firstChild,
            queryParams: back.queryParams,
        });
    }
}
